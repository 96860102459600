import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import $ from 'jquery';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  
]

const router = new VueRouter({
    mode:'history',
  
  

    routes: [
      {
        path: '/',
        name: 'index',
        component: () => import('../views/index.vue')
      },

      {
        path: '/404',
        name: '404',
       
        component: () => import('../views/404.vue')
      },
      {
        path: '/about-tectalik-company',
        name: 'about-tectalik-company',
       
        component: () => import('../views/about-tectalik-company.vue')
      },
      {
        path: '/become-a-partner-with-tectalik',
        name: 'become-a-partner-with-tectalik',
    
        component: () => import('../views/become-a-partner-with-tectalik.vue')
      },
      {
        path: '/best-features-of-tectalik-applications',
        name: 'best-features-of-tectalik-applications',
    
        component: () => import( '../views/best-features-of-tectalik-applications.vue')
      },
      {
        path: '/blog',
        name: 'blog',
    
        component: () => import( '../views/blog.vue')
      },
      {
        path: '/budgeting-business-forcasting',
        name: 'budgeting-business-forcasting',
    
        component: () => import( '../views/budgeting-business-forcasting.vue')
      },
      {
        path: '/business-health-watch-system',
        name: 'business-health-watch-system',
        
        component: () => import( '../views/business-health-watch-system.vue')
      },
      {
        path: '/contact',
        name: 'contact',
        
        component: () => import('../views/contact.vue')
      },
      {
        path: '/business-products-of-tectalik',
        name: 'business-products-of-tectalik',
        
        component: () => import( '../views/business-products-of-tectalik.vue')
      },
      {
        path: '/business-transformation',
        name: 'business-transformation',
        
        component: () => import('../views/business-transformation.vue')
      },
      {
        path: '/careers-at-tectalik',
        name: 'careers-at-tectalik',
        
        component: () => import( '../views/careers-at-tectalik.vue')
      },
      {
        
        path: '/connect-tectalik',
        name: 'connect-tectalik',
        
        component: () => import('../views/connect-tectalik.vue')
      },
      {
        path: '/crm-tips',
        name: 'crm-tips',
        
        component: () => import('../views/crm-tips.vue')
        
      },
      {
        path: '/customer-relationships-department',
        name: 'customer-relationships-department',
        
        component: () => import('../views/customer-relationships-department.vue')
        
      },
      {
        
        path: '/customers-of-tectalik',
        name: 'customers-of-tectalik',
        
        component: () => import( '../views/customers-of-tectalik.vue')
      },
      {
        path: '/dashboards-and-challenges-in-implementation',
        name: 'dashboards-and-challenges-in-implementation',
        
        component: () => import( '../views/dashboards-and-challenges-in-implementation.vue')
      },
      {
      
        path: '/demo-request',
        name: 'demo-request',
        
        component: () => import( '../views/demo-request.vue')
      },
      {
      
        path: '/design-automate-manage-analytics',
        name: 'design-automate-manage-analytics',
        
        component: () => import( '../views/design-automate-manage-analytics.vue')
      },
      {
      
        path: '/digital-transformation',
        name: 'digital-transformation',
        
        component: () => import( '../views/digital-transformation.vue')
      },
      {
      
        path: '/documentation',
        name: 'documentation',
        
        component: () => import( '../views/documentation.vue')
      },
      {
        path: '/events',
        name: 'events',
        
        component: () => import( '../views/events.vue')
      },

      {
        path: '/family-business-dos-and-donts',
        name: 'family-business-dos-and-donts',
        
        component: () => import( '../views/family-business-dos-and-donts.vue')
    
      },
      {
        
        path: '/family-business-second-generation',
        name: 'family-business-second-generation',
        
        component: () => import( '../views/family-business-second-generation.vue')
      },
      {
        path: '/frequently-asked-questions',
        name: 'frequently-asked-questions',
        
        component: () => import( '../views/frequently-asked-questions.vue')
      },
      
      {
        path: '/enterprise-risk-analytics-process',
        name: 'enterprise-risk-analytics-process',
        
        component: () => import( '../views/enterprise-risk-analytics-process.vue')
      },
      {
        path: '/how-to-prepare-your-business',
        name: 'how-to-prepare-your-business',
        
        component: () => import( '../views/how-to-prepare-your-business.vue')
      },
      {
        path: '/index',
        name: 'index',
       
        component: () => import('../views/index.vue')
      },
      {
       
        path: '/management-direction-and-key-business-metrics',
        name: 'management-direction-and-key-business-metrics',
       
        component: () => import( '../views/management-direction-and-key-business-metrics.vue')
      },
      {
        path: '/monitor-your-business',
        name: 'monitor-your-business',
       
        component: () => import( '../views/monitor-your-business.vue')
       
      },
      {
        path: '/performance-management-system',
        name: 'performance-management-system',
       
        component: () => import( '../views/performance-management-system.vue')
      },
      {
      path: '/plans-and-pricing',
      name: 'plans-and-pricing',
    
      component: () => import( '../views/plans-and-pricing.vue')
    },
    {
      path: '/powering-business-with-advanced-technologies',
      name: 'powering-business-with-advanced-technologies',
    
      component: () => import( '../views/powering-business-with-advanced-technologies.vue')
    },
    {
      path: '/precaution-for-covid-in-2020',
      name: 'precaution-for-covid-in-2020',
    
      component: () => import( '../views/precaution-for-covid-in-2020.vue')
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      
      component: () => import( '../views/privacy-policy.vue')
    },
    {
    
      path: '/product-suite-for-professional-firms',
      name: 'product-suite-for-professional-firms',
      
      component: () => import( '../views/product-suite-for-professional-firms.vue')
    },
    {
      path: '/proud-testimonials-of-tectalik',
      name: 'proud-testimonials-of-tectalik',
      
      component: () => import( '../views/proud-testimonials-of-tectalik.vue')
    },
    {
      path: '/reimagining-future-ready-organization',
      name: 'reimagining-future-ready-organization',
      
      component: () => import( '../views/reimagining-future-ready-organization.vue')
    },
      {
        path: '/riskbots-application',
        name: 'riskbots-application',
        
        component: () => import( '../views/riskbots-application.vue')
      },
      {
        path: '/roadmap-of-tectalik',
        name: 'roadmap-of-tectalik',
        
        component: () => import( '../views/roadmap-of-tectalik.vue')
      },
      {
        path: '/selecting-right-ceo-startups',
        name: 'selecting-right-ceo-startups',
        
        component: () => import( '../views/selecting-right-ceo-startups.vue')
      },
      {
      path: '/sitemap',
      name: 'sitemap',
      
      component: () => import( '../views/sitemap.vue')
      },
      {
      path: '/business-solutions',
      name: 'business-solutions',
      
      component: () => import( '../views/business-solutions.vue')
      },
    {
      path: '/compliance-monitoring-system',
      name: 'compliance-monitoring-system',
      
      component: () => import( '../views/compliance-monitoring-system.vue')
    },
    
    {
      path: '/SMAART_Expe_Digital_Transformation',
      name: 'SMAART_Expe_Digital_Transformation',
      
      component: () => import( '../views/SMAART_Expe_Digital_Transformation.vue')
    },
    {
    
    path: '/facilities-management-system',
    name: 'facilities-management-system',
    
    component: () => import('../views/facilities-management-system.vue')
    },
    {
    
      path: '/sales-team-management',
      name: 'sales-team-management',
      
      component: () => import( '../views/sales-team-management.vue')
      },
      {
        path: '/shared-services',
        name: 'shared-services',
        
        component: () => import( '../views/shared-services.vue')
      },
      {
        path: '/term-of-services',
        name: 'term-of-services',
        
        component: () => import( '../views/term-of-services.vue')
      }, 
      {
        path: '/videos',
        name: 'videos',
        
        component: () => import( '../views/videos.vue')
      },
      {
        path: '/why-choose-us',
        name: 'why-choose-us',
        
        component: () => import( '../views/why-choose-us.vue')
      },

      {
        path: '/Integrated-Cloud-ERP',
        name: 'Integrated-Cloud-ERP',
        
        component: () => import( '../views/Integrated-Cloud-ERP.vue')
      },
      {
        path: '/powerful_yourself_analytics_engine',
        name: 'powerful_yourself_analytics_engine',
        
        component: () => import( '../views/powerful_yourself_analytics_engine.vue')
      },
 
      {
        path: '/salespeople',
        name: 'salespeople',
        
        component: () => import('../views/salespeople.vue')
        
      },
      {
        path: '/customer-relationships',
        name: 'customer-relationships',
        
        component: () => import('../views/customer-relationships.vue')
        
      },
       {
        path: '/deadstock_business',
        name: 'deadstock_business',
        
        component: () => import('../views/deadstock_business.vue')
        
      },
       {
        path: '/precious_child',
        name: 'precious_child',
        
        component: () => import('../views/precious_child.vue')
        
      },
      {
        path: '/bizzare_attributes',
        name: 'bizzare_attributes',
        
        component: () => import('../views/bizzare_attributes.vue')
        
      },
       {
        path: '/marketing_officer',
        name: 'marketing_officer',
        
        component: () => import('../views/marketing_officer.vue')
        
      },
      {
        path: '/Salespeople_should_make_fans',
        name: 'Salespeople_should_make_fans',
        
        component: () => import('../views/Salespeople_should_make_fans.vue')
        
      },
      {
        path: '/salesperson_feel',
        name: 'salesperson_feel',
        
        component: () => import('../views/salesperson_feel.vue')
        
      },
      {
        path: '/Get_automated',
        name: 'Get_automated',
        
        component: () => import('../views/Get_automated.vue')
        
      },
      {
        path: '/salespeople_should',
        name: 'salespeople_should',
        
        component: () => import('../views/salespeople_should.vue')
        
      },
      {
        path: '/business_word',
        name: 'business_word',
        
        component: () => import('../views/business_word.vue')
        
      },
      {
        path: '/attention_please',
        name: 'attention_please',
        
        component: () => import('../views/attention_please.vue')
        
      },
      {
        path: '/upselling_principle',
        name: 'upselling_principle',
        
        component: () => import('../views/upselling_principle.vue')
        
      },
      {
        path: '/Kindness_builds',
        name: 'Kindness_builds',
        
        component: () => import('../views/Kindness_builds.vue')
        
      },
      {
        path: '/should_salespeople_prepare',
        name: 'should_salespeople_prepare',
        
        component: () => import('../views/should_salespeople_prepare.vue')
        
      },
       {
        path: '/store_watchman_help',
        name: 'store_watchman_help',
        
        component: () => import('../views/store_watchman_help.vue')
        
      },
      {
        path: '/Salesperson_Mean',
        name: 'Salesperson_Mean',
        
        component: () => import('../views/Salesperson_Mean.vue')
        
      },
      {
        path: '/seniors_treat_freshers',
        name: 'seniors_treat_freshers',
        
        component: () => import('../views/seniors_treat_freshers.vue')
        
      },
      {
        path: '/Store_Kingdom',
        name: 'Store_Kingdom',
        
        component: () => import('../views/Store_Kingdom.vue')
        
      },
      {
        path: '/Sales_Team',
        name: 'Sales_Team',
        
        component: () => import( '../views/Sales_Team.vue')
      },
      {
        path: '/seconds_Sales',
        name: 'seconds_Sales',
        
        component: () => import( '../views/seconds_Sales.vue')
      },
    
    {
      path: '/Customer-free-Store',
      name: 'Customer-free-Store',

      component: () =>
       import ('../views/Customer-free-Store.vue')
  },
  {
    path: '/your_customer',
    name: 'your_customer',

    component: () =>
     import ('../views/your_customer.vue')
},
{
  path: '/Blind_Salesman',
  name: 'Blind_Salesman',
  
  component: () =>
   import ('../views/Blind_Salesman.vue')
},
{
  path: '/SMILE_matter',
  name: 'SMILE_matter',

  component: () =>
   import ('../views/SMILE_matter.vue')
},
{
  path: '/Bill_smile',
  name: 'Bill_smile',

  component: () =>
   import ('../views/Bill_smile.vue')
},
{
  path: '/customers_deserve',
  name: 'customers_deserve',

  component: () =>
   import ('../views/customers_deserve.vue')
},
{
  path: '/Dead_rat',
  name: 'Dead_rat',
 
  component: () =>
   import ('../views/Dead_rat.vue')
},
{
  path: '/Retail_Dhoni',
  name: 'Retail_Dhoni',

  component: () =>
   import ('../views/Retail_Dhoni.vue')
},
{
  path: '/Role_organisation',
  name: 'Role_organisation',

  component: () =>
   import ('../views/Role_organisation.vue')
},
{
  path: '/customers_leave_your_store',
  name: 'customers_leave_your_store',

  component: () =>
   import ('../views/customers_leave_your_store.vue')
},
{
  path: '/Distribution_Business',
  name: 'Distribution_Business',
  
  component: () => import( '../views/Distribution_Business.vue')
},

{
  path: '/Accounts_and_Logistics',
  name: 'Accounts_and_Logistics',
  
  component: () => import( '../views/Accounts_and_Logistics.vue')
},
{
  path: '/Expertise_in_Manufacturing',
  name: 'Expertise_in_Manufacturing',
  
  component: () => import( '../views/Expertise_in_Manufacturing.vue')
},

{
  path: '/blog_admin_pages/:id',
  name: 'blog_admin_pages',
  
  component: () => import( '../views/blog_admin_pages.vue')
},

{
  path: '/Jewellery_ERP',
  name: 'Jewellery_ERP',
  
  component: () => import( '../views/Jewellery_ERP.vue')
},
{
  path: '/STAMP_Buiness_Transformation',
  name: 'STAMP_Buiness_Transformation',
  
  component: () => import( '../views/STAMP_Buiness_Transformation.vue')
},



{
  path: '/SMAART_Map_Assortment_planning',
  name: 'SMAART_Map_Assortment_planning',
  
  component: () => import( '../views/SMAART_Map_Assortment_planning.vue')
},

{
  path: '/Business_health_check',
  name: 'Business_health_check',
  
  component: () => import( '../views/Business_health_check.vue')
},
{
  path: '/Monthly_review_meeting',
  name: 'Monthly_review_meeting',
  
  component: () => import( '../views/Monthly_review_meeting.vue')
},
    ]
})

export default router
